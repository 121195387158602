<template>
  <div class="background">
    <img alt="Zafra Dental Clinic logo" src="../assets/ic_zafra_dental_text.svg">
      <!-- <li><a href="https://www.facebook.com/ZafraDentalClinic" target="_blank" rel="noopener">Zafra Dental Clinic</a></li>
    <h1>{{ msg }}</h1>
    <p>
      For a guide and recipes on how to configure / customize this project,<br>
      check out the
      <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.
    </p>
    <h3>Installed CLI Plugins</h3>
    <ul>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>
    </ul>
    <h3>Essential Links</h3>
    <ul>
      <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>
      <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>
      <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>
      <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>
      <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>
    </ul>
    <h3>Ecosystem</h3>
    <ul>
      <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul> -->

    <div class="row" style="margin: 100px 100px 10px">
        <div class="col">
            <img alt="Zafra Dental Clinic logo" src="../assets/ss_app.svg">
        </div>
        <div class="col" style="margin: 100px 0px 30px">
            <h1 style="color:white;">Download the Zafra Dental Clinic Now</h1>
            <p style="color:white;">
                Enjoy fast and easy appointment with our new Zafra Dental Clinic App!
            </p>
            <div class="row">
                <button class="col btn btn-default"><img alt="Zafra Dental Clinic logo" src="../assets/icons/apple-store-badge_1.svg"></button>
                <button class="col btn btn-default"><img alt="Zafra Dental Clinic logo" src="../assets/icons/google-play-badge_1.svg"></button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.background {
  background: #9618FF;
  /* background: white; */
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
