<template>
    <div class="container-fluid">
        <!-- <LoadingComponent :is_loading="loading_flag"/> -->
        <div class="row row-no-gutters">
            <div class="col-sm-4 card" style="background:#440C64;">
                <div class="card-body">
                    <div class="float-start">
                        <div class="d-flex">
                            <h4 style="color:#F9EF16">Contact Information</h4>
                        </div>
                        <div class="d-flex mb-3">
                            <p class="text-left" style="font-size:14px !important; color:white;">Fill up the form and our Team will get back to you within 24 hours.</p>
                        </div>
                        <div class="d-flex mb-3">
                            <a href="tel:09927389602">
                                <i class="fa-solid fa-phone"></i>
                                0992 738 9602
                            </a>
                        </div>
                        <div class="d-flex mb-3">
                            <a href="https://www.facebook.com/ZafraDentalClinic">
                                <i class="fab fa-facebook" style="font-size: 20px"></i>
                                Zafra Dental Clinic
                            </a>
                        </div>
                        <div class="d-flex mb-3">
                            <a href="https://m.me/ZafraDentalClinic">
                                <i class="fa-brands fa-facebook-messenger" style="font-size: 20px"></i>
                                Zafra Dental Clinic
                            </a>
                        </div>
                        <div class="d-flex mb-3">
                            <a href="mailto:zafradentalclinic@gmail.com">
                                <i class="fa-solid fa-envelope" style="font-size: 20px"></i>
                                zafradentalclinic@gmail.com
                            </a>
                        </div>
                        <div class="d-flex mb-3">
                            <a href="https://www.instagram.com/zafradentalclinic/?fbclid=IwAR23SSthDWLeYR1IaF4RgW2mFRvnOV-8MBtlJlk8Q0ptkOiG9HMk5RVUdjU">
                                <i class="fa-brands fa-instagram" style="font-size: 20px"></i>
                                @zafradentalclinic
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-8 card" style="background-color:lavenderblush;">
                <div class="card-body">
                    <form class="form-horizontal" action="/action_page.php">
                        <div class="form-group d-flex justify-content-center">
                            <div class="col">
                                <input v-model="email" type="text" class="form-control" placeholder="Enter Email Address">
                            </div>
                        </div>
                        <div class="form-group d-flex justify-content-center">
                            <div class="col">          
                                <input type="password" class="form-control" id="pwd" placeholder="Enter password" name="pwd">
                            </div>
                        </div>
                        
                        <div class="form-group d-flex justify-content-center">    
                            <div class="col">          
                                <textarea class="form-control" rows="5" id="comment"></textarea>
                            </div>
                        </div>
                        <div class="form-group d-flex justify-content-center">        
                            <div class="col-sm-offset-2 col">
                                <button type="submit" class="btn btn-default">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ContactUs',
}
</script>

<style>
</style>