<template >
    <div class="background">
        <div class="row mx-5" style="padding-top: 2%; padding-bottom: 2%;">
            <h1 class="mx-2" style="width: 100%; text-align: left;">Services Offered</h1>
            <div class="col-md-5" v-for="item in services" :key="item.message">
                <div class="row">
                    <div class="col-sm-2 col-xs-2"> </div>
                        <div class="col-sm-2 col-xs-1"> <img src="../assets/icons/tooth.png" class="img-responsive" style="width:50px; height:50px;"></div>
                        <div class="col-sm-5 col-xs-5"> <h2 style="text-align: left; padding-top: 1%; padding-bottom: 1%;">{{ item }}</h2> </div>
                    <div class="col-sm-4 col-xs-4" ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesPage',
    data() {
    return {
        services: [
            'Braces',
            'Oral Prophylaxis/Cleaning',
            'Tooth Restoration/Pasta',
            'Tooth Extraction/Bunot',
            'Tooth Extraction/Bunot',
            'Jacket Crown',
            'Fixed Partial Denture/Fixed Bridge',
            'Removable Partial Denture/Pustiso',
            'Complete Denture',
            'Teeth Whitening',
            'TMJ Disorder Pain Management'
        ]
        }
    }
}

</script>

<style scoped>
.background {
  background: #9618FF;
  /* background: white; */
}
h1, h2 {
  color: #F9EF16;
}
</style>