<template>
    <NavBar/>
  <router-view/>
</template>

<script>
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar
}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
img{
  margin: 0px !important;
  width: 100%;
  height: 100%; 
  object-fit: contain;
}

html, body {
  margin: 0px !important;
  padding: 0px !important;
}

@import'~bootstrap/dist/css/bootstrap.css';
</style>
